import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import Faq from '../components/Faq';
import footerChevron from '../theme/styles/footerChevron';

const styles = theme => ({
  ...container,
  ...section,
  ...footerChevron,
  faqsWrapper: {
    marginTop: theme.spacing.unit * 4,
  },
});

const FAQs = ({ classes, data, location }) => (
  <Layout location={location} title="Frequently Asked Questions">
    <div className={classNames(classes.containerSmall, classes.section)}>
      <Typography component="h1" variant="h1">
        Frequently Asked Questions
      </Typography>
      <div className={classes.faqsWrapper}>
        {data.allFaqsYaml.edges.map(({ node }, i) => (
          <Faq
            key={`faq-${i}`}
            question={node.question}
            answer={node.answer}
            index={i}
          />
        ))}
      </div>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(FAQs));

export const faqsQuery = graphql`
  query FaqsIndexQuery {
    allFaqsYaml {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`;
