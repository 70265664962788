import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';

const styles = theme => ({
  faq: {
    marginBottom: theme.spacing.unit,

    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing.unit * 2,
    },
  },
  faqQuestion: {
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'block',
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
    width: '100%',

    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  faqQuestionTitle: {
    margin: 0,
    paddingRight: theme.spacing.unit * 4,
    position: 'relative',
  },
  faqIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 15,
    height: 20,
  },
  faqAnswer: {
    maxHeight: '0',
    transition: 'max-height 0.3s ease-in-out',
    '.open &': {
      maxHeight: '500px',
    },
  },
});

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({
      ...this.state,
      open: !this.state.open,
    });
  }

  render() {
    const { question, answer, index, classes } = this.props;
    const isOpen = this.state.open;
    const toggleClass = isOpen ? 'open' : 'closed';
    const toggleAria = isOpen ? 'true' : 'false';
    const ariaControls = `faq-answer--${index}`;
    const faqId = `faq--${index}`;
    return (
      <Card className={classNames(classes.faq, toggleClass)} elevation={0}>
        <Button
          variant="outlined"
          className={classes.faqQuestion}
          aria-controls={ariaControls}
          aria-expanded={toggleAria}
          id={faqId}
          onClick={this.toggleOpen}
        >
          <Typography
            className={classes.faqQuestionTitle}
            component="h2"
            variant="h4"
          >
            {question}
            <FontAwesomeIcon
              className={classes.faqIcon}
              icon={isOpen ? faMinus : faPlus}
            />
          </Typography>
        </Button>
        <div
          className={classes.faqAnswer}
          id={ariaControls}
          aria-hidden={toggleAria}
          aria-labelledby={faqId}
        >
          <CardContent>{answer}</CardContent>
        </div>
      </Card>
    );
  }
}

Faq.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default withStyles(styles)(Faq);
